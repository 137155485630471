<template>
  <div class="user-with-picture">
    <img
      src="~/assets/images/avatar.png"
      draggable="false"
      class="user-with-picture__picture"
      :class="{
        'user-with-picture__picture user-with-picture__picture--small':
          withSmallAvatar
      }"
    />
    <p class="user-with-picture__email">
      {{ username }}
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'UserWithPicture',
  props: {
    username: {
      type: String,
      required: true
    },
    withSmallAvatar: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.user-with-picture {
  display: flex;
  align-items: center;

  &__picture {
    height: 32px;
    margin-right: $margin-small;

    &--small {
      height: 24px;
    }
  }
}
</style>
